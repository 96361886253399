import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PropTypes } from "prop-types"
import Fuse from "fuse.js"

import SearchIcon from "@mui/icons-material/Search"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { Button } from "@mui/material"

import AutocompleteComponent from "../common/autoComplete/autoComplete"
import SearchMoreConfirmationModal from "../common/searchMoreConfirmationModal/searchMoreConfirmationModal"
import ProgressBar from "../common/progressBar/ProgressBar"
import * as Types from "../../redux/actionTypes/index"
import * as actions from "../../redux/actions/appAction"
import { PUBLIC_PROVIDERS_COUNT } from "../../utils/constants"

// Debounce function
const debounce = (func, wait) => {
    let timeout
    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(this, args), wait)
    }
}

const GetCategoryList = ({
    isAddressModified,
    selectedCompleteAddress,
    type,
    openCategorySearchBar,
    selectedAddress,
    showNavSearchModal,
    currentProviderDetails,
    showCategoryPage
}) => {
    const {
        specialtyList = [],
        conditionsProceduresList = [],
        providersResponse,
        providerList = [],
        preferredList = [],
        diagnosisList = [],
        loginType,
        diagnosisResponse,
        radius,
        searchModalCached,
        showCategoryPageLevelError
    } = useSelector((state) => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categoryData, setCategoryData] = useState([])
    const [providerListCount, setProviderListCount] = useState(false)
    const [doctorListData, setDoctorListData] = useState([])
    const [selectedProvider, setSelectedProvider] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [categoryInputValue, setCategoryInputValue] = useState("")
    const [filterFormatData, setFilterFormatData] = useState([])
    const [diagnosisTotalCount, setDiagnosisTotalCount] = useState(0)
    const [pageLoadStatus, setPageLoadStatus] = useState(false)
    const [apiCallCount, setApiCallCount] = useState(1)

    const titleOrder = {
        "Practitioner,Physcian,V": 1,
        "HOSPITAL,Facility,GROUP/CLINIC": 2,
        procedureCode: 3,
        diagnosisCode: 4,
        specialties: 5
    }

    const processListOfData = (list, chunkSize) => {
        const obj = []
        let index = 0

        function processChunk() {
            const end = Math.min(index + chunkSize, list.length)
            for (let i = index; i < end; i++) {
                const itemData = list[i].data
                for (let j = 0; j < itemData.length; j++) {
                    obj.push(itemData[j])
                }
            }

            index = end

            if (index < list.length) {
                setTimeout(processChunk, 0) // Schedule the next chunk
            }
        }
        processChunk()
        return obj
    }
    useEffect(() => {
        let obj = []
        obj = processListOfData(doctorListData, 100000)
        // doctorListData?.forEach((el) => {
        //     obj.push(...el.data)
        // })

        obj.sort((a, b) => titleOrder[a.title] - titleOrder[b.title])
        setFilterFormatData(obj)
    }, [doctorListData])

    useEffect(() => {
        setDoctorListData([])
        const res = [
            ...specialtyList,
            ...conditionsProceduresList,
            ...providerList,
            ...preferredList,
            ...diagnosisList
        ]
        seperateResponse(res)
    }, [conditionsProceduresList])

    useEffect(() => {
        let dxCount = diagnosisResponse?.diagnosisCount || 0
        console.log(
            "diagnosisTotalCount: " +
                dxCount +
                " diagnosisList: " +
                diagnosisList?.length
        )
        const parsedCount = parseFloat(dxCount)

        if (diagnosisTotalCount === 0) {
            setDiagnosisTotalCount(parsedCount)
        }

        if (
            diagnosisList?.length &&
            diagnosisList?.length >= PUBLIC_PROVIDERS_COUNT &&
            diagnosisTotalCount > diagnosisList.length
        ) {
            getMoreDiagnosisList()
        }

        setCategoryData([])
        const res = [
            ...specialtyList,
            ...conditionsProceduresList,
            ...providerList,
            ...preferredList,
            ...diagnosisList
        ]
        seperateResponse(res)
    }, [diagnosisResponse, diagnosisTotalCount])

    useEffect(() => {
        let count = parseInt(providersResponse?.count || 0)
        console.log(
            "ProviderTotalCount: " +
                count +
                " ProviderList: " +
                providerList?.length
        )
        const totalAPICalls = Math.ceil(count / PUBLIC_PROVIDERS_COUNT)

        if (
            providerList?.length &&
            count >= PUBLIC_PROVIDERS_COUNT &&
            apiCallCount !== totalAPICalls &&
            searchModalCached === "true"
        ) {
            console.log(
                "Getting more providers. making call " + (apiCallCount + 1)
            )
            setApiCallCount(apiCallCount + 1)
            getMoreProviderList()
        }

        if (providerList?.length && count >= PUBLIC_PROVIDERS_COUNT) {
            setPageLoadStatus(true)
            if (searchModalCached === "false") {
                if (!pageLoadStatus) {
                    setProviderListCount(true)
                }
            }
        }

        setCategoryData([])
        const res = [
            ...specialtyList,
            ...conditionsProceduresList,
            ...providerList,
            ...preferredList,
            ...diagnosisList
        ]
        seperateResponse(res)
    }, [providersResponse])

    // handling click event on search button press
    const handleSearchClick = () => {
        dispatch(actions.setMapResults(selectedAddress))
        setShowProgressBar(true)
        dispatch({
            type: Types.GET_PROVIDER_DETAILS_FAIL
        })
        const timeout = type === "navSearch" ? 0 : 1000
        let selectedVal = selectedProvider
        // console.log(categoryData)
        if (!selectedVal) {
            selectedVal = categoryData[0].data[0]
        }
        setTimeout(() => {
            const res = {
                ...selectedVal,
                ...selectedCompleteAddress,
                offset: 0,
                navigate,
                type: type,
                pageChange: true,
                getAllResults: false
            }
            if (
                (type === "navSearch" && showCategoryPage) ||
                showCategoryPageLevelError
            ) {
                res["city"] = currentProviderDetails.city
                res["state"] = currentProviderDetails.state
                res["zipCode"] = currentProviderDetails.zipCode
            }
            let actionType =
                loginType === "external user login"
                    ? Types.GET_PUBLIC_PROVIDER_DETAILS
                    : Types.GET_PROVIDER_DETAILS

            if (selectedVal?.diagnosisCode) {
                res["codeType"] = "diagnosisCode"
                res["subType"] = "diagnosisCode"
                res["diagnosisCode"] = selectedVal.diagnosisCode
            } else if (selectedVal?.title === "specialties") {
                res["codeType"] = "specialtiesCode"
                res["subType"] = "specialties"
            } else if (selectedVal?.title === "procedureCode") {
                res["codeType"] = "procedureCode"
                res["subType"] = "procedureCode"
                res["procedureCodes"] = selectedVal.procedureCode
            }
            if (
                res["codeType"] === "procedureCode" ||
                res["codeType"] === "diagnosisCode" ||
                res["codeType"] === "specialtiesCode"
            ) {
                actionType =
                    loginType === "external user login"
                        ? Types.GET_PUBLIC_CONDITIONS_PROCEDURES_DETAILS_DATA
                        : Types.GET_CONDITIONS_PROCEDURES_DETAILS_DATA
            }

            dispatch({
                type: Types.SET_SELECTED_DROPDOWN_DETAILS,
                payload: res
            })
            dispatch({
                type: actionType,
                payload: res
            })
        }, timeout)
    }

    const seperateResponse = (list) => {
        let providersCategory = []
        // mapping response
        const doctorReponse = list || []
        const respAr = doctorReponse
        if (doctorReponse && doctorReponse.length > 0) {
            respAr.forEach((element) => {
                if (
                    element?.providerType === "HOSPITAL" ||
                    element?.providerType === "Facility" ||
                    element?.providerType === "GROUP/CLINIC"
                ) {
                    providersCategory.push("HOSPITAL,Facility,GROUP/CLINIC")
                } else if (
                    element?.providerType === "Practitioner" ||
                    element?.providerType === "Physcian" ||
                    element?.providerType === "V"
                ) {
                    providersCategory.push("Practitioner,Physcian,V")
                } else if (element?.procedureCode) {
                    providersCategory.push("procedureCode")
                } else if (element?.diagnosisCode) {
                    providersCategory.push("diagnosisCode")
                }
            })
        }
        const specialtiesReponse = specialtyList ? specialtyList : []
        // console.log(specialtiesReponse, "specialtiesReponse")
        if (specialtiesReponse && specialtiesReponse.length > 0) {
            providersCategory.push("specialties")
        }
        if (providersCategory?.length) {
            providersCategory = [...new Set(providersCategory)]
        }

        // grouping data based on provider_type
        const filterData = []
        if (providersCategory && providersCategory.length > 0) {
            providersCategory.forEach((element, index) => {
                const object = {
                    title: element,
                    data: [],
                    id: index
                }
                if (element === "procedureCode") {
                    respAr?.forEach((item) => {
                        const val = {
                            ...item,
                            searchKeyValue: item?.procedureCategory || "",
                            searchAltKeyValue: item?.procedureDescription || "",
                            providerName: item?.procedureDescription || "",
                            title: element,
                            providerId: item?.procedureCode
                        }
                        object["data"].push(val)
                    })
                    filterData.push(object)
                } else if (element === "diagnosisCode") {
                    respAr?.forEach((item) => {
                        const val = {
                            ...item,
                            searchKeyValue:
                                item?.diagnosisCodeDescription || "",
                            //searchAltKeyValue:
                            //item?.diagnosisCodeDescription || "",
                            providerName: item?.diagnosisCodeDescription || "",
                            title: element,
                            providerId: item?.diagnosisCode
                        }
                        object["data"].push(val)
                    })
                    filterData.push(object)
                } else if (element !== "specialties") {
                    respAr?.forEach((item) => {
                        if (element.includes(item?.providerType)) {
                            const val = {
                                ...item,
                                searchKeyValue: item?.providerName || "",
                                //searchAltKeyValue: item?.providerName || "",
                                title: element
                            }
                            object["data"].push(val)
                        }
                    })
                    filterData.push(object)
                } else if (
                    element === "specialties" &&
                    specialtiesReponse?.length
                ) {
                    specialtiesReponse?.forEach((item) => {
                        const val = {
                            ...item,
                            searchKeyValue: item?.specialtyCategory || "",
                            searchAltKeyValue: item?.specialtyDesc || "",
                            title: element,
                            providerName: item?.specialtyDesc || "",
                            providerId: item?.specialtyCode
                        }
                        object["data"].push(val)
                    })
                    filterData.push(object)
                }
            })
        }

        //  group common provider names
        const finalSearchObj = filterData
        if (filterData && filterData.length > 0) {
            filterData.forEach((element, i) => {
                const modifiedObj = []
                const uniqueValues = new Set()
                element?.data?.forEach((item) => {
                    if (!uniqueValues.has(item.providerName)) {
                        uniqueValues.add(item.providerName)

                        let obj = {
                            ...item,
                            providerName: item.providerName,
                            unique_id: item.providerId
                        }

                        modifiedObj.push(obj)
                    }
                })

                finalSearchObj[i].data = modifiedObj
            })
        }

        setCategoryData(finalSearchObj)
        setDoctorListData(finalSearchObj)
    }

    const getMoreProviderList = () => {
        const postData = {
            ...selectedCompleteAddress,
            radius,
            navigate,
            offset: providerList?.length,
            count: parseInt(providersResponse?.count || 0)
        }
        dispatch({
            type:
                loginType === "external user login"
                    ? Types.GET_PUBLIC_PROVIDERLIST
                    : Types.GET_PROVIDERLIST,
            payload: postData
        })
    }

    const getMoreDiagnosisList = () => {
        const postData = {
            ...selectedCompleteAddress,
            navigate,
            offset: diagnosisList?.length,
            count: diagnosisResponse?.diagnosisCount
        }
        dispatch({
            type:
                loginType === "external user login"
                    ? Types.GET_PUBLIC_DIAGNOSIS_DATA
                    : Types.GET_DIAGNOSIS_DATA,
            payload: postData
        })
    }

    const handleModalClose = (val) => {
        if (val === "1") {
            dispatch({ type: Types.RESET_PROVIDERLIST })
            dispatch({
                type: "SET_CATEGORY_VIEW_SHOW",
                payload: false
            })
        } else if (val === "2") {
            getMoreProviderList()
        }
        setProviderListCount(false)
    }
    const searchData = (val) => {
        setSelectedProvider(val)
    }
    const fuseOption = useMemo(
        () => ({
            keys: ["searchKeyValue", "searchAltKeyValue"],
            threshold: 0.2,
            useExtendedSearch: true
        }),
        []
    )
    const fuse2Option = useMemo(
        () => ({
            keys: ["procedureCode"],
            threshold: 0.0,
            useExtendedSearch: true
        }),
        []
    )

    const fuse = useMemo(
        () => new Fuse(filterFormatData, fuseOption),
        [filterFormatData, fuseOption]
    )

    const fuse2 = useMemo(
        () => new Fuse(filterFormatData, fuse2Option),
        [filterFormatData, fuse2Option]
    )

    // const handleFuseSearch = debounce((mValue) => {
    //     const res = fuse2.search(mValue)
    //     return res || []
    // }, 200)

    const handleFuse2Search = debounce((mValue, value) => {
        let res = fuse2.search(mValue)
        let matchCode = []
        if (res.length) {
            matchCode = res.filter(
                (a) =>
                    a.item.procedureCode.toUpperCase() === value.toUpperCase()
            )
            const r = res.filter(
                (a) =>
                    a.item.procedureCode.toUpperCase() !==
                        value.toUpperCase() &&
                    !a.item.procedureCode.includes(",")
            )

            r.sort((a, b) => {
                return a.item.procedureCode.localeCompare(
                    b.item.procedureCode,
                    undefined,
                    {
                        numeric: true,
                        sensitivity: "base"
                    }
                )
            })

            res = [...r]
        }

        const res1 = fuse.search(mValue)
        const res2 = res1 || []
        const result = matchCode.length
            ? [...matchCode, ...res2, ...res]
            : [...res2, ...res]

        const viewTileMap = new Map([
            [
                "Practitioner,Physcian,V",
                { id: -1, title: "Practitioner,Physcian,V", data: [] }
            ],
            [
                "HOSPITAL,Facility,GROUP/CLINIC",
                { id: -1, title: "HOSPITAL,Facility,GROUP/CLINIC", data: [] }
            ],
            ["procedureCode", { id: -1, title: "procedureCode", data: [] }],
            ["specialties", { id: -1, title: "specialties", data: [] }]
        ])

        let viewTileOrder = []
        if (result.length) {
            result.forEach(({ item }) => {
                let category = viewTileMap.get(item.title)

                if (category) {
                    category.data.push(item)
                } else {
                    category = viewTileMap.get("procedureCode")
                    category.data.push(item)
                }

                if (!viewTileOrder.includes(category.title)) {
                    viewTileOrder.push(category.title)
                }
            })

            const viewTileList = viewTileOrder?.map((title, i) => {
                let category = viewTileMap.get(title)
                category.id = i
                return category
            })

            setCategoryData(viewTileList)
        } else {
            setCategoryData([])
        }
    }, 100)

    // on keypress filtering data
    const handleKeyPress = (value) => {
        const list = doctorListData
        setCategoryInputValue(value)

        if (!value) {
            setCategoryData(list)
            return
        }

        let mValue = value
        if (value.includes(" ")) {
            mValue = value.trim().replaceAll(" ", " '")
        }

        // const fuse = new Fuse(filterFormatData, {
        //     keys: ["searchKeyValue", "searchAltKeyValue"],
        //     threshold: 0.2,
        //     useExtendedSearch: true
        // })

        // const fuse2 = new Fuse(filterFormatData, {
        //     keys: ["procedureCode"],
        //     threshold: 0.0,
        //     useExtendedSearch: true
        // })

        // let res = fuse2.search(mValue)
        handleFuse2Search(mValue, value)
        // let matchCode = []
        // if (res?.length) {
        //     matchCode = res.filter(
        //         (a) =>
        //             a.item.procedureCode.toUpperCase() === value.toUpperCase()
        //     )
        //     const r = res.filter(
        //         (a) =>
        //             a.item.procedureCode.toUpperCase() !==
        //                 value.toUpperCase() &&
        //             !a.item.procedureCode.includes(",")
        //     )

        //     r.sort((a, b) => {
        //         return a.item.procedureCode.localeCompare(
        //             b.item.procedureCode,
        //             undefined,
        //             {
        //                 numeric: true,
        //                 sensitivity: "base"
        //             }
        //         )
        //     })

        //     res = [...r]
        // }
        // const fuseRsp = handleFuseSearch(mValue) || []
        // const result = matchCode.length
        //     ? [...matchCode, ...fuseRsp, ...res]
        //     : [...fuseRsp, ...res]

        // const viewTileMap = new Map([
        //     [
        //         "Practitioner,Physcian,V",
        //         { id: -1, title: "Practitioner,Physcian,V", data: [] }
        //     ],
        //     [
        //         "HOSPITAL,Facility,GROUP/CLINIC",
        //         { id: -1, title: "HOSPITAL,Facility,GROUP/CLINIC", data: [] }
        //     ],
        //     ["procedureCode", { id: -1, title: "procedureCode", data: [] }],
        //     ["specialties", { id: -1, title: "specialties", data: [] }]
        // ])

        // let viewTileOrder = []
        // console.log(result)
        // if (result.length) {
        //     result.forEach(({ item }) => {
        //         let category = viewTileMap.get(item.title)

        //         if (category) {
        //             category.data.push(item)
        //         } else {
        //             category = viewTileMap.get("procedureCode")
        //             category.data.push(item)
        //         }

        //         if (!viewTileOrder.includes(category.title)) {
        //             viewTileOrder.push(category.title)
        //         }
        //     })

        //     const viewTileList = viewTileOrder?.map((title, i) => {
        //         let category = viewTileMap.get(title)
        //         category.id = i
        //         return category
        //     })

        //     setCategoryData(viewTileList)
        // } else {
        //     setCategoryData([])
        // }
    }
    return (
        <>
            <label className="category-label" htmlFor="autoComplete">
                Navigate to better care.
            </label>
            <br />
            <AutocompleteComponent
                handleSelectRow={searchData}
                suggestions={categoryData}
                handleKeyPress={handleKeyPress}
                isAddressModified={isAddressModified}
            />
            {providerListCount && searchModalCached !== "true" && (
                <SearchMoreConfirmationModal handleClose={handleModalClose} />
            )}
            <div className="criteria-button mt-4">
                <Button
                    variant="text"
                    onClick={() => {
                        openCategorySearchBar()
                        dispatch({
                            type: "SET_CATEGORY_VIEW_SHOW",
                            payload: false
                        })
                        dispatch({
                            type: "SHOW_CATEGORY_PAGE",
                            payload: false
                        })
                        dispatch({
                            type: "SET_SELECTED_CATEGORY_PAGE_LEVEL_ERROR"
                        })
                        dispatch({
                            type: "SHOW_PAGE_LEVEL_ERROR"
                        })
                    }}
                    color="primary"
                    aria-label="Previous"
                    startIcon={<KeyboardArrowLeftIcon />}
                >
                    Previous
                </Button>
                <Button
                    disabled={!categoryInputValue}
                    variant="text"
                    color="primary"
                    aria-label="search"
                    onClick={handleSearchClick}
                    startIcon={<SearchIcon />}
                >
                    Search
                </Button>
            </div>
            {showProgressBar && !showNavSearchModal && <ProgressBar />}
        </>
    )
}

GetCategoryList.propTypes = {
    showFld: PropTypes.any,
    isAddressModified: PropTypes.any,
    selectedCompleteAddress: PropTypes.any,
    type: PropTypes.any,
    openCategorySearchBar: PropTypes.any,
    selectedAddress: PropTypes.any,
    showNavSearchModal: PropTypes.any,
    currentProviderDetails: PropTypes.any,
    showCategoryPage: PropTypes.any
}

export default GetCategoryList
