import React, { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Route,
    Routes,
    Navigate,
    useLocation,
    useNavigate
} from "react-router-dom"
import { CountdownCircleTimer } from "react-countdown-circle-timer"

import Header from "./components/header/Header"
import Landing from "./view/landing/Landing"
import ProviderResults from "./view/provider-results/ProviderResults"
import Footer from "./components/footer/Footer"
import CircularLoader from "./components/common/circularLoader/CircularLoader"
import ErrorPage from "./view/error/errorPage"
import PublicLanding from "./view/publicLanding/publicLanding"
import GenerateProvidersReport from "./view/generate-providers-report/GenerateProvidersReport"
import {
    getDirectLandingUrlParams,
    currentNavigation,
    getHeaderStatus
} from "./utils/common"
import { ThemeContext } from "./Theme"
import * as Types from "./redux/actionTypes/index"
import DrugResultPage from "./components/drug-search/ResultPage"

import "./App.css"
import "./idleTimer.css"

import PageNotFound from "./view/page-not-found/PageNotFound"
import { Button, Modal } from "@mui/material"
import { Box } from "@mui/system"
import {
    BLACK_THEME,
    PURPLE_THEME,
    MEMBER_VIRGINPULSE_URL,
    MEMBER_VIRGINPULSE_PROD_URL,
    PS_HOME_URL
} from "../src/utils/constants"
import { modalStyles } from "../src/utils/common"

const RouterHandler = () => {
    const navigate = useNavigate()
    const { pathname: currentPath } = useLocation()
    const prvRef = useRef()

    useEffect(() => {
        const pat = prvRef.current
        if (currentPath !== pat) {
            if (
                !pat &&
                currentPath !== "/internal" &&
                currentPath !== "/customerService" &&
                currentPath !== "/" &&
                currentPath !== "/DrugResults" &&
                currentPath !== "/employers/"
            ) {
                navigate("/", { replace: true })
            }
        }
        prvRef.current = currentPath
    }, [prvRef, navigate])
}
function App() {
    const location = useLocation()
    const dispatch = useDispatch()
    const params = new URLSearchParams(location.search)
    const store = useSelector((state) => state)
    const { showLoader, tokenInfo } = store
    const { theme } = useContext(ThemeContext)
    const [showNav, setShowNav] = useState(false)
    const [showIdlePrompt, setShowIdlePrompt] = useState(false)
    const [idleTimer, setIdleTimer] = useState(null)
    const [sessionExpired, setSessionExpired] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("loginType")
            sessionStorage.removeItem("userInfo")
            sessionStorage.removeItem("empType")
            sessionStorage.removeItem("url")
        }
    }, [])
    useEffect(() => {
        if (location.pathname === "/employers/") {
            sessionStorage.setItem("empType", true)
            sessionStorage.setItem("url", location.pathname + location.search)
        }
        if (location.pathname === "/") {
            handlePageLoad()
        }
    }, [location])

    const handlePageLoad = () => {
        const { sponsorId, employerId, planId } =
            getDirectLandingUrlParams(params)
        const sessionLoginType = sessionStorage.getItem("loginType")
        const empStatus = sessionStorage.getItem("empType")
        const url = sessionStorage.getItem("url")
        if (empStatus) {
            dispatch({ type: "IS_LOGIN_URI", payload: true })
            navigate(url)
            return true
        } else if (
            !(sponsorId && employerId && planId) &&
            location.pathname === "/"
        ) {
            if (sessionLoginType === "external user login") {
                const obj = sessionStorage.getItem("userInfo")
                const userInfo = JSON.parse(obj)
                if (userInfo) {
                    dispatch({ type: "GET_USER_INFO_SUCCESS", userInfo })
                    dispatch({
                        type: "LOGIN_TYPE",
                        payload: "external user login"
                    })
                    dispatch({ type: "IS_LOGIN_URI", payload: false })
                }
            } else {
                sessionStorage.removeItem("loginType")
                sessionStorage.removeItem("userInfo")
                dispatch({ type: Types.GET_TOKEN })
            }
        }
    }

    const routeChange = () => {
        setShowIdlePrompt(false)
        if (window.location.origin === PS_HOME_URL) {
            window.location.href = MEMBER_VIRGINPULSE_PROD_URL
        } else {
            window.location.href = MEMBER_VIRGINPULSE_URL
        }
    }

    useEffect(() => {
        if (idleTimer) {
            clearTimeout(idleTimer)
            setIdleTimer(null)
        }
        if (tokenInfo?.expires_in) {
            const idleTimer = setTimeout(() => {
                setShowIdlePrompt(true)
            }, (tokenInfo?.expires_in - 300) * 1000)
            setIdleTimer(idleTimer)
        }
    }, [tokenInfo])

    useEffect(() => {
        setShowNav(location.pathname)
    }, [location])

    const handleStay = () => {
        if (idleTimer) {
            clearTimeout(idleTimer)
            setIdleTimer(null)
            setShowIdlePrompt(false)
        }

        const { sponsorId, employerId, planId } =
            getDirectLandingUrlParams(params)
        if (!(sponsorId && employerId && planId) && location.pathname === "/") {
            dispatch({ type: Types.GET_TOKEN })
        }
    }

    const formatTime = (remainingTime) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60
        return minutes + ":" + seconds
    }

    const renderTimer = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setSessionExpired(true)
            return (
                <div className="idleTimerExp">
                    <div>Session</div>
                    <div>Expired</div>
                </div>
            )
        }

        return (
            <div className="idleTimer">
                <div className="timer">{formatTime(remainingTime)}</div>
            </div>
        )
    }

    const getHeaderClasses = () => {
        return (
            "App-header" +
            (location.pathname === "/DrugResults" ? "addShadow" : "")
        )
    }
    const applyCoupeBgImage = () => {
        return theme === "coupe-health" && location.pathname === "/DrugResults"
    }

    const applySimplePayBgImage = () => {
        return (
            theme === "simplePay-health" && location.pathname === "/DrugResults"
        )
    }
    const isPublicUrl = () => {
        return (
            location.pathname !== "/internal" &&
            location.pathname !== "/customerService"
        )
    }

    return (
        <div className={`App ${theme}`}>
            {theme && isPublicUrl() && (
                <header className={getHeaderClasses()}>
                    <Header />
                </header>
            )}
            <div
                className={`app-content 
                ${currentNavigation(showNav) && "home-content"}
                ${applyCoupeBgImage() ? "bgImg-coupe" : ""}
                ${applySimplePayBgImage() ? "bgImg-simplePay" : ""}
                ${getHeaderStatus(showNav) && "no-app-header"}`}
            >
                {/* <RouterHandler> */}
                <RouterHandler />
                <Routes>
                    <Route excat path="/" element={<Landing />} />
                    <Route excat path="/employers/" element={<Landing />} />
                    <Route excat path="/error" element={<ErrorPage />} />
                    <Route excat path="/internal" element={<PublicLanding />} />
                    <Route
                        excat
                        path="/customerService"
                        element={<PublicLanding />}
                    />
                    <Route
                        excat
                        path="/pageNotFound"
                        element={<PageNotFound />}
                    />
                    <Route
                        path="/ProviderResults"
                        element={<ProviderResults />}
                    />
                    <Route path="/DrugResults" element={<DrugResultPage />} />
                    <Route
                        path="*"
                        element={<Navigate replace to="/pageNotFound" />}
                    />
                    <Route
                        path="/generateProviderReport"
                        element={<GenerateProvidersReport />}
                    />
                </Routes>
                {/* </RouterHandler> */}
            </div>

            {showLoader ? <CircularLoader /> : ""}
            {showIdlePrompt && (
                <Modal
                    open={true}
                    aria-labelledby="timer-modal-title"
                    aria-describedby="timer-modal-description"
                    className="countDownModal"
                >
                    <Box sx={modalStyles} className="timerBox">
                        {sessionExpired ? (
                            <span className="routeChange" onClick={routeChange}>
                                X
                            </span>
                        ) : null}
                        <CountdownCircleTimer
                            isPlaying
                            duration={300}
                            className="countDownTimer"
                            colors={`${
                                theme === "coupe-health"
                                    ? BLACK_THEME
                                    : PURPLE_THEME
                            }`}
                            size={"100"}
                        >
                            {renderTimer}
                        </CountdownCircleTimer>
                        <br />
                        {sessionExpired ? (
                            <div className="outer">
                                <div className="inner">
                                    Your session has timed out. Please log back
                                    in to continue searching.
                                </div>
                            </div>
                        ) : (
                            <div className="timeout-section">
                                <div className="timeout-div">
                                    Your session is about to timeout. Would you
                                    like to continue searching?
                                </div>
                                <Button
                                    variant="contained"
                                    className={`yesBtn ${
                                        theme === "coupe-health"
                                            ? "coupe-health"
                                            : theme === "simplePay-health" &&
                                              "simplePay-health"
                                    }`}
                                    onClick={handleStay}
                                >
                                    Yes
                                </Button>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}
            {theme &&
                location.pathname !== "/internal" &&
                location.pathname !== "/customerService" && <Footer />}
        </div>
    )
}

export default App
